<template>
  <v-container fluid class="mt-n5">
    <v-row align="center">
      <v-col cols="12" md="7">
        <v-row>
          <v-col cols="12" md="4">
            <v-card class="elevation-0 py-5">
              <h4>Total Users</h4>
              <h4>{{ total_contacts }}</h4>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="elevation-0 py-5">
              <h4>Registered Today</h4>
              <h4>{{ total_today }}</h4>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="elevation-0 py-5">
              <h4>This Month</h4>
              <h4>{{ total_this_month }}</h4>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="5">
        <v-row class="px-3" justify-md="end">
          <v-btn
            outlined
            class="primary--text rounded-xl capitalize"
            @click="exportContacts"
          >
            Export Contacts
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="contacts"
          item-key="email"
          hide-default-footer
          :loading="loadingContacts"
        >
          <template v-slot:item.number="{ index }">
            <div>{{ index + 1 }}</div>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon color="error" @click="deleteContact(item)">
              mdi-trash-can-outline
            </v-icon>
          </template>
        </v-data-table>
        <div v-if="contacts.length && pagination.total > 1" class="mt-4">
          <v-pagination
            v-model="pagination.page"
            :length="pagination.total"
            :total-visible="7"
            @input="nextPage()"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import exportFromJSON from "export-from-json";
import { mapActions } from "vuex";
export default {
  name: "ContactList",
  data() {
    return {
      headers: [
        { text: "#", value: "number", sortablr: false, orderable: false },
        { text: "First Name", value: "first_name" },
        { text: "Last Name", value: "last_name" },
        { text: "Email", value: "email" },
        { text: "Date registered", value: "created_at" },
        { text: "Action", value: "action" },
      ],
      contacts: [],
      pagination: {
        page: 1,
        total: 1,
      },
      total_contacts: 0,
      total_today: 0,
      total_this_month: 0,
      fileName: "contacts",
      exportType: exportFromJSON.types.csv,
      loadingContacts: false,
    };
  },
  created() {
    this.getContacts();
  },
  methods: {
    ...mapActions({
      get_contacts: "contacts/get_contacts",
      add_contacts: "contacts/add_contacts",
      delete_contact: "contacts/delete_contact",
    }),
    async getContacts(page = null) {
      let data = page;
      if (!page) {
        data = "?page=" + this.pagination.page;
      }
      this.loadingContacts = true;
      try {
        const response = await this.get_contacts(data);
        this.contacts = response.data.contacts.data;
        this.pagination.total = response.data.contacts["last_page"];
        this.pagination.page = response.data.contacts["current_page"];
        this.total_contacts = response.data.total;
        this.total_this_month = response.data.this_month;
        this.total_today = response.data.today;
        this.loadingContacts = false;
      } catch (e) {
        console.log(e);
        this.loadingContacts = false;
      }
    },
    async deleteContact(contact) {
      const confirmDelete = confirm("This will permanently delete the contact");
      if (!confirmDelete) return;
      this.loadingContacts = true;
      try {
        const response = await this.delete_contact(contact.id);
        this.loadingContacts = false;
        this.$response.sendSuccess(response);
        this.getContacts();
      } catch (e) {
        this.loadingContacts = false;
        this.$response.sendError(e);
      }
    },
    exportContacts() {
      const data = this.contacts;
      const fileName = this.fileName;
      const exportType = this.exportType;
      if (!data.length) {
        this.$response.staticError("Contact list is empty");
        return;
      }
      exportFromJSON({ data, fileName, exportType });
    },
    nextPage() {
      const page = "?page=" + this.pagination.page;
      this.getContacts(page);
    },
  },
};
</script>
